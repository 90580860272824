import React, {useState} from 'react';
import {Alert, Box, Button, Grid, Input} from "@mui/material";
import './Home.css'
import {useNavigate, useSearchParams} from "react-router-dom";

const Home=()=> {
    const navigate = useNavigate();

    const [code, setCode] = useState();

    const [searchParams] = useSearchParams();
    const isValid = searchParams.get('valid');
    const handleCheckNow = ()=> {
        navigate(`/ch?c=${code}`)
    }
    return (
      <>
          <Grid sx={{backgroundColor:' #f4f4f5',width:'100%',height:'100%'}}>
              <Grid className='wrapper' sx={{background:'#fff',margin:'auto'}}>
                  <Grid
                      container spacing={0} direction="column" alignItems="center" sx={{marginBottom:'40px'}}>

                      <h2 style={{textAlign:'center',color:'#000',padding:'1px 0 0.5px'}} className="query-title">Product Checking</h2>
                      <p className="query-title-intro query-intro">Basic Information and Chemical product Checking</p>
                      <Input type="text" value={code} onChange={(e)=>setCode(e.target.value)} className="query-input"
                             placeholder="Please enter product's code."/>
                      {isValid === 'false' && <Alert sx={{margin:'10px'}} severity="error">This code is incorrect, please check again!</Alert>}
                      <Button disabled={code ? false : true} variant="contained" color='warning' sx={{width: '24rem'}} onClick={handleCheckNow} >Check now</Button>
                  </Grid>
                  <Grid sx={{borderTop:'1px solid #e2e2e2'}}>
                      <Box>
                          <h3 className="tips-title">Warm Reminder</h3>
                          <ul className="tips-list tips-content">

                              <li className="tips-item">There may be a delay in Checking data inquiries. If your
                                  information is not displayed, please try again after 48 hours.

                              </li>

                          </ul>
                      </Box>
                  </Grid>

              </Grid>
          </Grid>


      </>
  )
}
export default Home;