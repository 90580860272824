import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../config";

const ref = collection(db,'products');
// Add a new document in collection "cities"
export const createNewProduct = async (product) => {
    await addDoc(ref, product).then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
    })
        .catch(function(error) {
            console.error("Error adding document: ", error);
        });

}

export const findByCode = async (code) => {
    try {
        const searchQuery = query(ref,where('code','==',code))
        const data = [];
         await getDocs(searchQuery).then((querySnapshot) => {

            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                data.push({
                    id: doc.id,
                    ...docData,
                });
            });
        })
        return data[0];
    } catch (err) {
        console.log(err)
    }

}
