import * as React from 'react';
import {Outlet} from "react-router-dom";
import {Footer} from "./Footer";
import "./Main.css"
export function Main() {
    return (
        <>
            <header>
            </header>
            <Outlet  />
            <Footer/>
        </>
    );
};