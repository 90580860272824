// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyADGACAnP4BQEDd6x2xSou7YU4Ng4DM3WU",
    authDomain: "qr-product-checking.firebaseapp.com",
    projectId: "qr-product-checking",
    storageBucket: "qr-product-checking.appspot.com",
    messagingSenderId: "486140393795",
    appId: "1:486140393795:web:529110e4c2af2914a897f6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app)