import * as React from 'react';
import {useEffect} from 'react';
import {Box, Grid} from "@mui/material";
import "./ProductInfo.css"
import {useDispatch, useSelector} from "react-redux";
import {findProductAsync, selectProduct} from "../features/product/productSlice";
import {useNavigate, useSearchParams} from "react-router-dom";
import Loading from "../component/loading";
import {Helmet} from "react-helmet-async";

const ProductInfo = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const {product,status} = useSelector(selectProduct);
    const navigate = useNavigate();


    useEffect(() => {
        const code = searchParams.get('c');
        dispatch(findProductAsync(code))
    },[searchParams,dispatch] );
    if(status ==='idle' && product === undefined){
        navigate('/en/sp/process-check?valid=false');
    }

    const handleConvertTimeStamptoDate = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        return `${year}/${month}`
    }
    if(status === 'loading')
        return <Loading/>
    else
    return (
        <>
            <Helmet>
                <title> Information of the chemical</title>
            </Helmet>

        <Grid className={'parent-wrapper'} sx={{backgroundColor:'#fff', width:'100%',height:'100%', marginBottom:'20px'}}>
            <Box className={'wrapper'}>
                <Grid className="information" sx={{backgroundColor:'#fff'}} container direction="row" justifyContent="center">
                    <Grid item xs={12} sm={12} md={4} sx={{marginTop:'20px'}}>
                        <div className="result-left">
                            {product && <div className="result-img" style={{backgroundImage:`url(${product.image})`,textAlign:'center', margin:'auto',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat', height:'210px', width:'160px',border:'none'}} >
                                {/*<img className="img" src={product && product.image} alt=""/>*/}
                            </div>}
                            <p className="img-tip">Remarks：Products in the picture shown are for illustration only. Any
                                specific product is listed below.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <div className="result-right">
                            <h4 className="result-model" >Chemical product's information</h4>
                            <p className="model" style={{fontWeight:900}}>{product && product.name}</p>
                            <div className="result-detail">
                                <p>
                                  <span className="name">Batch Code:&nbsp;</span>
                                    <span className="value" style={{fontWeight:600}}>{product && product.code}</span>

                                </p>
                                <Grid  container
                                       direction="row"
                                       justifyContent="flex-start"
                                       alignItems="center">
                                    <span style={{marginTop:'auto',marginBottom:'auto',textAlign:'center'}} className="name">Brand:&nbsp; </span>
                                    <span className="value">
                                        {product && product.brandLogo ? <img alt="product" style={{width:'40px',display:"inline"}} src={product.brandLogo}/> :
                                        product && product.brand}
                                    </span>

                                </Grid>
                                <p>
                                    <span className="name">Manufacture Time:&nbsp;</span><span className="value">{product && handleConvertTimeStamptoDate(product.dateOfManufacture)}</span>
                                </p>

                                    {product && product.brand === 'Kopran' &&
                                        <p>
                                            <span className="name">Manufactured by:&nbsp;</span>
                                            <span className="value">{product.manufacturedBy}</span>
                                        </p>}
                                {product && product.brand === 'Neclife' &&
                                    <p>
                                        <span className="name">Manufactured At:&nbsp;</span>
                                        <span className="value">{product.manufacturedAt}</span>
                                    </p>}

                            </div>
                            <div className="result-help">
                                <p>Should you have any questions, please proceed to Customer Serivce Center for verification.</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
        </>
    );
};

export default ProductInfo;