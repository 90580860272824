import * as React from 'react';
import "./Footer.css"


export const Footer = () => {
    return (
        <footer>
            <div className="wrapper">
                <small>&copy;2017 All Rights Reserved</small>
                <nav className="footer-nav">
                    <a href="#">Back to Top</a>
                    <a href="#">Terms of Use</a>
                    <a href="#">Privacy</a>
                </nav>
            </div>
        </footer>
    );
};