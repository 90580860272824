import React, {PureComponent} from 'react';
import {CircularProgress, Grid} from "@mui/material";

class Loading extends PureComponent {
    render() {
        return (
            <Grid container justify="center" alignItems="center">
                <CircularProgress size={64} value={10} sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}/>
            </Grid>
        );
    }
}

Loading.propTypes = {};

export default Loading;