import React from 'react';
import {createRoot} from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';

import {Provider} from 'react-redux';
import {store} from './app/store';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import Home from "./pages/Home";
import GenQR from "./pages/GenQR";
import ProductInfo from "./pages/ProductInfo";
import Page404 from "./pages/Page404";
import ThemeProvider from "./theme";
import {Main} from "./layouts/dasboard/Main";
import SimpleLayout from "./layouts/simple";

const container = document.getElementById('root');
const root = createRoot(container);

const router = createBrowserRouter([
    {
        element: <Main/>,
        children: [
            {
                path: "/en/sp/process-check",
                element: <Home/>,
            },
            // {
            //     path: "/gen",
            //     element: <GenQR/>,
            // },
            {
                path: "/ch",
                element: <ProductInfo/>,
            },
            {
                element: <SimpleLayout />,
                children: [
                    { element: <Navigate to="/en/sp/process-check" />, index: true },
                    { path: '404', element: <Page404 /> },
                    { path: '*', element: <Navigate to="/404" /> },
                ],
            },
            {
                path: '*',
                element: <Navigate to="/404" replace />,
            }
        ]
    }

]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <HelmetProvider>
            <ThemeProvider>
                <RouterProvider router={router} />
            </ThemeProvider>
        </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
