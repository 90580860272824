import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createNewProduct, findByCode} from "../../service/Firebase";

const initialState = {
    product: null,
    status: 'idle',
};
export const findProductAsync = createAsyncThunk(
    'product/fetchProduct',
    async (code) => {
        const response =  await findByCode(code)
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const addProductAsync = createAsyncThunk(
    'product/addProduct',
    async (product) => {
        const response =  await createNewProduct(product)
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);



export const productSlice = createSlice({
    name: 'product',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    // reducers: {
    //   increment: (state) => {
    //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //     // doesn't actually mutate the state because it uses the Immer library,
    //     // which detects changes to a "draft state" and produces a brand new
    //     // immutable state based off those changes
    //     state.value += 1;
    //   },
    //   decrement: (state) => {
    //     state.value -= 1;
    //   },
    //   // Use the PayloadAction type to declare the contents of `action.payload`
    //   incrementByAmount: (state, action) => {
    //     state.value += action.payload;
    //   },
    // },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(findProductAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(findProductAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.product = action.payload;
            })
            .addCase(addProductAsync.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(addProductAsync.fulfilled,(state,action) => {
                state.status = 'idle';
                state.isAdded = true;
            });

    },
});

export const selectProduct = (state) => state.product;
export default productSlice.reducer;
